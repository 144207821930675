<template>
  <div>
    <v-card flat class="card-preview">
      <div style="margin-left: 10px; margin-right: 10px">
        <v-card-title class="pt-8"
          style="
          font-size: 18px;
          margin-left: 1rem;
          margin-right: 1rem;
          color: #444444;">
          {{ $t('envelope') }}
        </v-card-title>
        <v-card-text>
          <v-list dense>
            <v-list-item
              v-for="(report, index) in reportList"
              :key="index"
              @click="$router.push(report.route)"
              class="report-list-item"
              >
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{ $t(report.title) }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t(report.description) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        reportList: [
          {
            title: 'envelopeReport',
            description: 'envelopeReportDescription',
            route: '/envelopereport'
          },
          {
            title: 'envelopeRecipientReport',
            description: 'envelopeRecipientReportDescription',
            route: '/enveloperecipientreport'
          },
          {
            title: 'envelopeStatusReport',
            description: 'envelopeStatusReportDescription',
            route: '/envelopestatusreport'
          },
          {
            title: 'envelopeVelocityReport',
            description: 'envelopeVelocityReportDescription',
            route: '/envelopevelocityreport'
          },
          {
            title: 'envelopeVolumeReport',
            description: 'envelopeVolumeReportDescription',
            route: '/envelopevolumereport'
          },
          {
            title: 'envelopeAuthenticationReport',
            description: 'envelopeAuthenticationReportDescription',
            route: '/envelopeauthenticationreport'
          },
          {
            title: 'purgedEnvelopeReport',
            description: 'purgedEnvelopeReportDescription',
            route: '/purgedenvelopereport'
          },
          {
            title: 'failedDeliveryReport',
            description: 'failedDeliveryReportDescription',
            route: '/faileddeliveryreport'
          }
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  .card-preview {
    border-radius: 5px !important;
    height: 100% !important;
    padding: 0;
  }

  .report-list-item {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .v-list-item-title {
    margin-bottom: 4px;
  }
  
  .v-list-item-subtitle {
    color: #757575;
  }
  </style>