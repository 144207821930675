<template>
<div>
    <v-app>
        <v-content>
            <v-container>
                 <v-row>
                    <v-col cols="12" sm="12" md="12" lg="6">
                        <v-row>
                            <v-col cols="12">
                                <div style="font-size: 75px; text-shadow: rgba(0, 0, 0, 0.3) 3px 3px 5px; margin: 90px 0px 15px;" transition="slide-x-transition">
                                    <h1 style="font-family: Kanit;font-weight: 500;margin: 0px; font-size: 1em; color: rgb(39, 116, 186);">
                                        <p class="font-weight-bold">{{$t('register1')}}</p>
                                        <!-- <p class="font-weight-bold">นาน 3 เดือน</p> -->
                                    </h1>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="2">
                                <v-img 
                                    class="center"
                                    transition="scale-transition"
                                    :src="images.cloud"
                                    width="70%"
                                ></v-img>
                            </v-col>
                            <v-col cols="10">
                                <div class="fusion-text fusion-text-1" style="font-size:16px;color:#313131;font-family:&quot;Kanit&quot;;">
                                    <p>-{{$t('register3')}} <br>{{$t('register4')}}</p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="2">
                                <v-img 
                                    class="center"
                                    transition="scale-transition"
                                    :src="images.people"
                                    width="50%"
                                ></v-img>
                            </v-col>
                            <v-col cols="10">
                                <div class="fusion-text fusion-text-1" style="font-size:16px;color:#313131;font-family:&quot;Kanit&quot;;">
                                    <p>-{{$t('register5')}} <br>{{$t('register6')}}</p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="2">
                                <v-img 
                                    class="center"
                                    transition="scale-transition"
                                    :src="images.group"
                                    width="70%"
                                ></v-img>
                            </v-col>
                            <v-col cols="10">
                                <div class="fusion-text fusion-text-1" style="font-size:16px;color:#313131;font-family:&quot;Kanit&quot;;">
                                    <p>-{{$t('register7')}} </p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="2">
                                <v-img 
                                    class="center"
                                    transition="scale-transition"
                                    :src="images.contact"
                                    width="70%"
                                ></v-img>
                            </v-col>
                            <v-col cols="10">
                                <div class="fusion-text fusion-text-1" style="font-size:16px;color:#313131;font-family:&quot;Kanit&quot;;">
                                    <p>-{{$t('register8')}} <br>{{$t('register9')}} <br>{{$t('register10')}}</p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="mt-6">
                            <v-col cols="12">
                                <v-img
                                    class="center"
                                    transition="scale-transition"
                                    :src="images.server"
                                    width="100%"
                                ></v-img>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="6">
                        <v-row>
                            <v-col cols="12">
                                <div style="font-size: 40px; margin: 90px 0px 15px; visibility: visible; animation-duration: 0.3s;" data-animationtype="zoomInLeft" data-animationduration="0.3" data-animationoffset="100%">
                                    <h3 style="font-family: 'Kanit'; font-weight: 500; margin: 0px; font-size: 1em; color: rgb(39, 116, 186);line-height: 1.16;">
                                        <p class="text-center font-weight-bold">{{$t('register2')}}</p>
                                    </h3>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row> 
                            <v-col cols="6" class="font-text-field">
                                <p class="mytitle">{{$t('register11')}}</p>
                                <v-text-field
                                    v-model="name"
                                    :rules="[rules.required1]"
                                    single-line
                                    outlined
                                    required
                                >
                                <template #message="{ message }">
                                    {{ $t(message) }}
                                </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" class="font-text-field">
                                <p class="mytitle">{{$t('register12')}}</p>
                                <v-text-field
                                    v-model="lastName"
                                    :rules="[rules.required2]"
                                    single-line
                                    outlined
                                    required
                                >
                                <template #message="{ message }">
                                    {{ $t(message) }}
                                </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters> 
                            <v-col cols="12" class="font-text-field">
                                <p class="mytitle">{{$t('register13')}}</p>
                                <v-text-field
                                    v-model="nameCompany"
                                    :rules="[rules.required4]"
                                    single-line
                                    outlined
                                    required
                                >
                                <template #message="{ message }">
                                    {{ $t(message) }}
                                </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters> 
                            <v-col cols="12" class="font-text-field">
                                <p class="mytitle">{{$t('register14')}}</p>
                                <v-text-field
                                    v-model="address"
                                    :rules="[rules.required5]"
                                    single-line
                                    outlined
                                    required
                                >
                                <template #message="{ message }">
                                    {{ $t(message) }}
                                </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters> 
                            <v-col cols="12" class="font-text-field">
                                <p class="mytitle">{{$t('register15')}}</p>
                                <v-text-field
                                    v-model="city"
                                    :rules="[rules.required6]"
                                    single-line
                                    outlined
                                    required
                                    v-on:keypress="isLetter($event)"
                                >
                                <template #message="{ message }">
                                    {{ $t(message) }}
                                </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters> 
                            <v-col cols="12" class="font-text-field">
                                <p class="mytitle">{{$t('register16')}}</p>
                                <v-text-field
                                    v-model="country"
                                    :rules="[rules.required7]"
                                    single-line
                                    outlined
                                    required
                                    v-on:keypress="isLetter($event)"
                                >
                                <template #message="{ message }">
                                    {{ $t(message) }}
                                </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters> 
                            <v-col cols="12" class="font-text-field">
                                <p class="mytitle">{{$t('register17')}}</p>
                                <v-text-field
                                    v-model="companyAbbr"
                                    :rules="[rules.required8]"
                                    @keypress="validateCompanyCode($event)"
                                    single-line
                                    maxlength="5"
                                    outlined
                                    required
                                >
                                <template #message="{ message }">
                                    {{ $t(message) }}
                                </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters> 
                            <v-col cols="12" class="font-text-field">
                                <p class="mytitle">{{$t('register18')}}</p>
                                <v-text-field
                                    maxlength="15"
                                    v-model="username"
                                    :rules="[rules.required3]"
                                    @keypress="validateCompanyCode($event)"
                                    single-line
                                    outlined
                                    required
                                >
                                <template #message="{ message }">
                                    {{ $t(message) }}
                                </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters> 
                            <v-col cols="12" class="font-text-field">
                                <p class="mytitle">{{$t('register19')}}</p>
                                <v-text-field
                                    v-model="mail"
                                    :rules="[rules.required9,rules.requiredmail]"
                                    single-line
                                    outlined
                                    required
                                >
                                <template #message="{ message }">
                                    {{ $t(message) }}
                                </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters> 
                            <v-col cols="12" class="font-text-field">
                                <p class="mytitle">{{$t('register20')}}</p>
                                <v-text-field
                                    v-model="phone"
                                    :rules="[rules.required10]"
                                    single-line
                                    maxlength="11"
                                    outlined
                                    required
                                    @keypress="isNumber($event)"
                                >
                                <template #message="{ message }">
                                    {{ $t(message) }}
                                </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters> 
                            <v-col cols="12" class="font-text-field">
                                <v-btn
                                  @click="Register()"
                                  class="font-text-field "
                                  color="primary"
                                  block>
                                  {{$t('register21')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-content>
    </v-app>

    <Loading
      :flagLoading="flagLoadingSend"
    />
    
    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />

</div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
// import store from "../store";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";
import i18n from '@/plugins/i18n';

export default {
  components: {
    Alert,
    Loading
  },
  props: {
    size: {
      type: String,
      default: "10",
    },
    characters: {
      type: String,
      default: "a-z,A-Z,0-9,#",
    },
  },
  data(){
      return{
        url: enurl.apiUrl,
        flagLoadingSend: false,
        images: {
            cloud: require("@/assets/Group-2657.png"),
            people: require("@/assets/Group-2658.png"),
            group: require("@/assets/Group-2663.png"),
            contact: require("@/assets/Group-2664.png"),
            server: require("@/assets/Group-2713.png"),
        } ,
        username:"",
        name:"",
        lastName:"",
        nameCompany:"",
        address: "",
        city: "",
        country: "",
        companyAbbr: "",
        mail: "",
        phone: "",
        pw:"",
        rules: {
          required1: v => !!v || 'validateregister1',
          required2: v => !!v || 'validateregister2',
          required3: v => !!v == true ? (v.length < 16 || 'validateregister3a') : 'validateregister3',
          required4: v => !!v || 'validateregister4',
          required5: v => !!v || 'validateregister5',
          required6: v => !!v || 'validateregister6',
          required7: v => !!v || 'validateregister7',
          required8: v => !!v || 'validateregister8',
          required9: v => !!v || 'validateregister9',
          requiredmail: v=> /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,6})$/.test(v) || 'invalid',
          required10: v => !!v || 'validateregister10'
        },
        flagAlertSend: false,
        mesAlertSend: "",
        typeAlertSend: "success",
        lang: "",
      }
  },
  mounted() {
    const queryString = new URLSearchParams(window.location.search);
    const urlParams = queryString.get('lang');
    // i18n.locale = urlParams;
    i18n.locale = urlParams != 'th' ? 'en' : urlParams;
  },
  watch:{

  },
  computed: {

  },
  methods:{
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      // self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if(/^[A-Za-z]+$/.test(char)) return true; // Match with regex 
      else e.preventDefault(); // If not match, don't add to input text
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validateCompanyCode(e) {
        let char = String.fromCharCode(e.keyCode); // Get the character
        if (/^[a-zA-Z0-9]+$/.test(char)) return true;
        else e.preventDefault(); // If not match, don't add to input text
    },
    validateEmail(email) {
      // eslint-disable-next-line no-useless-escape
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(email) || "invalid";
    },
    generate: function () {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }

      for (let i = 0; i < this.size; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      return password;
    },
    Register() {
      var self = this;
      if (
        self.name =="" ||
        self.lastName =="" ||
        self.username =="" ||
        self.nameCompany =="" ||
        self.address == "" ||
        self.city == "" ||
        self.country == "" ||
        self.companyAbbr == "" ||
        self.validateEmail(self.mail) == "invalid" ||
        self.phone.length < 9 ||
        self.phone.length > 11 
      ) {
        // // alert("Please fill in all fields.");
        self.mesAlertSend = "Please fill in all fields.";
        self.typeAlertSend = "warning"; //success warning error
        self.flagAlertSend = true;
      } else if(self.username != null && self.username.length > 15){
        self.mesAlertSend = "User name with more than 15 characters is not allowed.";
        self.typeAlertSend = "warning"; 
        self.flagAlertSend = true;
      }
      else {
        self.pw = self.generate();
        var temp = {
          companyName: self.nameCompany,
          companyCode: self.companyAbbr,
          telephoneNo: self.phone,
          email: self.mail,
          country: self.country,
          city: self.city,
          address: self.address,
          status: true,
          employeeExtJoinRequest: {
            username: self.username,
            firstName: self.name,
            lastName: self.lastName,
            email: self.mail,
            password: self.pw,
            mobile: self.phone,
            EmployeePermission : [{
              PermissionID :1
            }],EmployeeRole : [{
              RoleID :2
            }],
          },
        };
        self.flagLoadingSend = true;
        axios
          .post(`${self.url}Company/RegisterCompany`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.SendMailRegister();
            } else {
              // alert("Company Code/Email already registered.");
              self.mesAlertSend = "Company Code/Email already registered.";
              self.typeAlertSend = "warning"; //success warning error
              self.flagAlertSend = true;
              self.flagLoadingSend = false;
            }
          })
          .catch(function (error) {
            // alert(error);
            self.mesAlertRed = error;
            self.flagAlertRed = true;
            self.flagLoadingSend = false;
          });
      }
    },
    SendMailRegister(){
      var self = this;
      var temp = {
        email: self.mail,
        name: self.name,
        user: self.username,
        pass: self.pw,
        companyAbbr: self.companyAbbr
      };
      axios
          .post(`${self.url}Company/SendMailRegister`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              // alert(response.data.message);
              self.mesAlertSend = response.data.message;
              self.typeAlertSend = "success"; //success warning error
              self.flagAlertSend = true;
              self.flagLoadingSend = false;
              window.location.reload();
              // self.username = "";
              // self.name = "";
              // self.lastName = "";
              // self.nameCompany = "";
              // self.address = "";
              // self.city = "";
              // self.country = "";
              // self.companyAbbr = "";
              // self.mail = ""
              // self.phone = "";
              // self.pw = "";
            } else {
              // alert("Send Mail Registered Fail.");
              self.mesAlertSend = "Send Mail Registered Fail.";
              self.typeAlertSend = "warning"; //success warning error
              self.flagAlertSend = true;
              self.flagLoadingSend = false;
            }
          })
          .catch(function (error) {
            // alert(error);
            self.mesAlertSend = error;
            self.typeAlertSend = "error"; //success warning error
            self.flagAlertSend = true;
            self.flagLoadingSend = false;
          });
    }
  }
}
</script>
<style>
    .font-text-field {
        font-family: 'Kanit';
        color: rgb(39, 116, 186);
    }
    .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
</style>