<template>
  <div>
    <v-img 
      :src="images.background"
      
      :style="[$vuetify.breakpoint.smAndDown ? { 'min-height': '100vh' } : { 'height': '100vh' }]">
      <div>
        <v-container>
          <v-row 
            style="min-height: 100vh; margin-top: -12px" 
            justify="center" 
            align="center">
            <v-card 
              width="420" 
              :class="$vuetify.breakpoint.xs ? 'rounded-0' : 'rounded-lg'">
              <v-card-text style="overflow: hidden !important">
                <v-alert type="error" v-model="erroralert">{{ errorMessage }}</v-alert>
                <v-alert type="info" v-model="successalert">{{ successMessage }}</v-alert>
                <!-- <v-tabs class="d-none" v-model="model"></v-tabs> -->
                <v-tabs-items v-model="model">
                  <!-- Login -->
                  <v-tab-item :value="`tab-1`">
                    <v-img :src="images.logo" width="200" class="mx-auto"></v-img>
                    <h2 class="mb-3">Log In</h2>
                    <label for="companycode" style="font-size: 16px">Company Code</label>
                    <v-text-field
                      id="companycode"
                      outlined
                      v-model="input.company_code"
                      @keyup.enter="login"
                      style="margin-bottom: -15px"
                    ></v-text-field>
                    <label for="username" style="font-size: 16px">Username</label>
                    <v-text-field
                      maxlength="15"
                      id="username"
                      outlined
                      v-model="input.username"
                      @keyup.enter="login"
                      style="margin-bottom: -15px"
                    ></v-text-field>
                    <label for="psw" style="font-size: 16px">Password</label>
                    <v-text-field
                      id="psw"
                      outlined
                      type="password" 
                      v-model="input.password" 
                      @keyup.enter="login"
                      style="margin-bottom: -15px"
                    ></v-text-field>
                    <v-btn 
                      :loading="loading" 
                      :disabled="loading" 
                      block 
                      color="primary" 
                      class="mt-3 rounded-lg" 
                      x-large 
                      style="border-radius: 6px"
                      @click="login">Log In</v-btn>
                    <br>
                    <a 
                      class="mt-5" 
                      style="color: #126496" 
                      @click="model = 'tab-5'">Forgot your password?</a>
                  </v-tab-item>

                  <!-- Forgot Your Password -->
                  <v-tab-item 
                    :value="`tab-5`">
                    <v-alert 
                      type="error" 
                      v-show="errorMessage != ''">{{ errorMessage }}</v-alert>
                    <div 
                      class="d-flex">
                      <h2>Forgot your password?</h2>
                      <v-btn 
                        v-show="!flagForgot" 
                        text 
                        class="ml-auto" 
                        color="primary" 
                        @click="model = 'tab-1'">
                        <v-icon>mdi-chevron-left</v-icon>
                        <span>Go back to login page</span>
                      </v-btn>
                    </div>
                    <div 
                      v-if="!flagForgot">
                      <p 
                        class="mt-5" align="justify">
                        Please enter your account email address to reset your password and you will receive an email
                        with instructions in a few minutes, if you do not get an email or have any problems, please
                        try again or contact <a color="primary">Support</a>
                      </p>
                      <v-row>
                        <v-col 
                          cols="12" 
                          xs="12" 
                          sm="12" 
                          md="12">
                          <v-text-field 
                            v-model="Email" 
                            hide-details="auto" 
                            label="Email"></v-text-field>
                        </v-col>
                        <v-col>
                          <v-btn 
                            block 
                            :loading="loading"
                            :disabled="loading"
                            color="primary" 
                            x-large 
                            class="rounded-lg"
                            style="border-radius: 6px" 
                            @click="forgot()">Submit</v-btn>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      <br />
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12">
                          <v-btn
                            block
                            disabled
                            large
                            class="rounded-lg"
                            style="background-color:#12649640 !important"
                          >
                            <span style="color:#126496"> <img width="20" src="../assets/images/correct.png"> Forgot your password successfully !</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12">
                          <v-btn
                            block
                            color="primary"
                            x-large
                            class="rounded-lg"
                            @click="model = 'tab-1', flagForgot = false"
                            >Go back to login page</v-btn
                          >
                        </v-col>
                      </v-row>
                    </div>
                    <br />
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-row>
        </v-container>
      </div>
    </v-img>
    <v-footer 
      color="primary" 
      :absolute="$vuetify.breakpoint.height < 786 ? false : true" 
      class="white--text text-caption hidden-md-and-down">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="10" md="10">
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="4" md="4" lg="2">
              <v-img :src="images.logo" width="52" class="mx-auto"></v-img>
            </v-col>
            <v-col>
              <span>Fusion Solution Co., Ltd 77/148 34 Fl. Sinsathorn Tower, Klongtonsai, Klongsarn, Bangkok 10600</span>
              <div class="d-flex">
                <span>Terms of Use</span>
                <v-divider vertical inset class="mx-3" dark></v-divider>
                <span>Privacy Policy</span>
                <v-divider vertical inset class="mx-3" dark></v-divider>
                <span>Cookies Policy</span>
                <v-divider vertical inset class="mx-3" dark></v-divider>
                <span>Privacy Notice</span>
                <v-divider vertical inset class="mx-3" dark></v-divider>
              </div>
            </v-col>
            <v-col>
              <div>For more Information</div>
              <div>TEL: 02 440 0408</div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" xs="12" sm="2" md="2">
          <v-btn 
            icon 
            color="white"
            class="float-right" 
            style="margin-top: 0.5rem;">
            <v-icon style=" opacity: 1">mdi-arrow-up-circle-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
    <!-- <v-layout>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6" class="logocss" style="margin:0;padding:0;">
          <v-img class="img-intersection" :src="images.BG" />
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" style="margin:0;padding:0;background-color:white;">
          <div style="position:relative;">
            <v-row align="center" justify="center" class="img-intersection">
              <v-col cols="12" sm="8" md="8">
                <v-img
                  align-center
                  justify-center
                  alt="Fusion Logo"
                  class="form ml-3 mr-3"
                  :src="images.logo"
                  transition="scale-transition"
                />
                <v-alert type="error" v-model="erroralert">{{ errorMessage }}</v-alert>
                <v-alert type="info" v-model="successalert">{{ successMessage }}</v-alert>

                <v-form v-show="forgotpassword" class="form ml-3 mr-3">
                  <v-text-field
                    style="margin-top:3rem;"
                    placeholder="Enter your Email"
                    label="Email"
                    v-model="Email"
                    name="login"
                  />
                </v-form>
                <v-layout class="mt-5" flex align-center justify-center>
                  <v-btn
                    v-show="forgotpassword"
                    class="forgot mt-4"
                    large
                    color="primary"
                    align="center"
                    justify="center"
                    @click="forgot"
                    :loading="loading"
                  >Send</v-btn>

                  <v-btn
                    v-show="forgotpassword"
                    class="forgot mt-4 ml-2"
                    large
                    color="primary"
                    align="center"
                    justify="center"
                    v-on:click="forgotpassword=!forgotpassword"
                  >Cancel</v-btn>
                </v-layout>

                <v-form v-show="!forgotpassword" class="form ml-3 mr-3">
                   <v-text-field
                    placeholder="company code"
                    class="mt-4"
                    label="Company Code"
                    name="login"
                    type="text"
                    v-model="input.company_code"
                  />
                  <v-text-field
                    placeholder="username"
                    class="mt-4"
                    :label="$t('username')"
                    name="login"
                    type="text"
                    v-model="input.username"
                  />
                  <v-text-field
                    placeholder="password"
                    class="mt-4"
                    :label="$t('password')"
                    type="password"
                    @keyup.enter="login"
                    v-model="input.password"
                  />
                  <v-row>
                    <v-checkbox :label="$t('rememberme')" required v-model="rememberMe"></v-checkbox>
                    <v-spacer />

                    <a
                      v-on:click="forgotpassword=!forgotpassword"
                      class="forgotPassword"
                    >{{$t('forgotpassword')}}</a>
                  </v-row>
                </v-form>

                <v-layout flex align-center justify-center>
                  <v-btn
                    v-show="!forgotpassword"
                    class="login mt-4"
                    large
                    color="primary"
                    align="center"
                    justify="center"
                    @click="login"
                    :loading="loading"
                  >{{$t('login')}}</v-btn>
                </v-layout>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-layout>-->

    <!-- <Loading
      :flagLoading="flagLoadingSend"
    />
    
    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />  -->

  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/api/function.js";
// import store from "../store";
// import Alert from '@/components/Alert';
// import Loading from "@/components/Loading";

export default {
  components: {
    // Alert,
    // Loading,
  },
  mixins: [LogTrace],
  name: "Login",
  data() {
    return {
      expiryDate: "",
      rememberMe: false,
      model: "tab-1",
      url: enurl.apiUrl,
      flagLoadingSend: false,
      loading: false,
      forgotpassword: false,
      erroralert: false,
      successalert: false,
      successMessage: "",
      errorMessage: "",
      show1: false,
      remember: "",
      secretKey: "SecretPassphrase@2020",
      permissionID: [],
      flagForgot: false,
      input: {
        company_code: "",
        username: "",
        password: "",
      },
      Email: "",
      images: {
        logo: require("@/assets/VLogo.png"),
        BG: require("@/assets/orange2.jpg"),
        background: require("@/assets/images/login_bg.png")
      },

      flagAlertSend: false,
      mesAlertSend: "",
      typeAlertSend: "success",
    };
  },
  mounted: function () {
    var self = this;

    this.DeleteCookie();
    // Clear cookie 
    // document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";  
    // document.cookie = "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; 

    self.remember = self.readCookie("rememberme");
    if (self.remember == "true") {
      let token = localStorage.getItem('token');
      self.$store
        .dispatch("checktoken", { token })
        .then(function (response) {
          // console.log(response);
          if (response.data.message != "Invalid Token!") {
            var data = response.data.data;
            localStorage.setItem("token", response.data.data.token);

            if (data.userType == "EXT") {
              setTimeout(() => {
                // let MenuIds = sessionStorage.getItem("MenuId").split(",");
                // const first_MenuID = MenuIds[0];
                // let route = "usermanagement";
                // if (first_MenuID == 11) {
                //   route = "usermanagement";
                // } else if (first_MenuID == 22) {
                //   route = "usergroup";
                // } else if (first_MenuID == 33) {
                //   route = "permission";
                // } else if (first_MenuID == 1) {
                //   route = "menumanagement";
                // } else if (first_MenuID == 2) {
                //   route = "calendar";
                // } else if (first_MenuID == 3) {
                //   route = "log";
                // }
                //   const min_per =  Math.min(...data.permissionId);
                //   switch(min_per) {
                //     case 1:
                //     case 2:
                //       self.$router.push("/UploadPDF");
                //       break;
                //     case 3:
                //     case 4:
                //       self.$router.push("/historylog");
                //       break;
                //     case 5:
                //     case 6:
                //     case 7:
                //     case 8:
                //       self.$router.push("/CertList");
                //       break;
                //     case 9:
                //     case 10:
                //     case 11:
                //     case 12:
                //     case 13:
                //     case 14:
                //     case 15:
                //     case 16:
                //     case 17:
                //     case 18:
                //     case 19:
                //     case 20:
                //     case 21:
                //       self.$router.push("/usermanagement");
                //       break;
                //     default:
                //       self.$router.push("/AccessDenied");
                //       break;
                //   }
              }, 1000);
              self.$router.push("/UploadPDF");
              // document.cookie =
              //   "username=" +
              //   self.cryptoMethods("encrypt", data.username) +
              //   ";expires=" +
              //   self.expiryDate;
              // // document.cookie =
              // //   "password=" +
              // //   self.cryptoMethods("encrypt", password) +
              // //   ";expires=" +
              // //   self.expiryDate;
              // let tempRem = self.remember == "true" ? self.remember : self.rememberMe;
              // document.cookie = "rememberme=" + tempRem + ";expires=" + self.expiryDate;
            }
          }
        })
        .catch(function (error) {
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
          // self.erroralert = true;
          // self.errorMessage = error;
          // self.loading = false;
        });
    }

    // let login_username = this.readCookie("username");
    // let login_password = this.readCookie("password");
    // this.remember = this.readCookie("rememberme");
    // if (this.remember == "true") {
    //   this.expiryDate = this.readCookie("expiredate");
    //   let today = new Date();
    //   if (today >= this.expiryDate) {
    //     document.cookie = "username=" + ";expires=" + self.expiryDate;
    //     document.cookie = "password=" + ";expires=" + self.expiryDate;
    //     document.cookie = "rememberme=" + ";expires=" + self.expiryDate;
    //   } else {
    //     if (login_username != null && login_password != null) {
    //       this.input.username = this.cryptoMethods("decrypt", login_username);
    //       this.input.password = this.cryptoMethods("decrypt", login_password);
    //       // this.login();
    //     }
    //   }
    // } else {
    //   this.expiryDate = new Date();
    //   this.expiryDate.setMonth(this.expiryDate.getMonth() + 1);
    //   document.cookie = "expiredate=" + this.expiryDate;
    // }
  },
  methods: {
    async DeleteCookie() {
      let self = this;
      await axios
        .post(`${self.url}Login/Logout`)
        .then(resp => {
            sessionStorage.removeItem("token"); 
            sessionStorage.removeItem("usertype");
            sessionStorage.removeItem("username");
            sessionStorage.removeItem("permissionid");
            sessionStorage.removeItem("firstName");
            sessionStorage.removeItem("lastName");
            sessionStorage.removeItem("email");
            sessionStorage.removeItem("mobile");
            sessionStorage.removeItem("pathImage");
            sessionStorage.removeItem("employeeID");
            sessionStorage.removeItem("companyID");
            sessionStorage.removeItem("flagUserAD");
            sessionStorage.removeItem("pdpa");
            console.log(resp)
        })
        .catch(function (error) {
          console.log(error);
        });  
    },
    ClearDataAlert() {
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    forgot() {
      try {
        let self = this;
        self.flagLoadingSend = true;
        self.loading = true;
        let tempEmail = {
          email: self.Email,
          isUser: false
        };
        axios
          .post(`${self.url}Login/ForgotPassword`, tempEmail)
          .then(function (response) {
            if(response.data.status == 0){
              self.loading = false;
              self.Email = "";
              self.dialog = false;
              self.flagForgot = true;
            }
            else{
              self.errorMessage = response.data.message;
              self.loading = false;
            }
          })
          .catch(function (error) {
            self.errorMessage = error;
            self.flagForgot = false;
            self.loading = false;
            self.dialog = false;
          });
          // .post(`${self.url}Login/ForgotPassword`, tempEmail)
          // .then(function (response) {
          //   self.typeAlertSend = "success"; //success warning error
          //   self.mesAlertSend = response.data.message;
          //   self.flagAlertSend = true;
          //   self.loading = false;
          //   self.Email = "";
          //   self.flagLoadingSend = false;
          // })
          // .catch(function (error) {
          //   self.typeAlertSend = "error"; //success warning error
          //   self.mesAlertSend = error;
          //   self.flagAlertSend = true;
          //   self.loading = false;
          //   self.flagLoadingSend = false;
          // });
      } catch (error) {
        this.LogTrace(error, "forgotPassword");
      }
    },
    async login () {
      // console.log(this.input.company_code, this.input.username, this.input.password)

      let self = this;

      self.clearMessage();
      self.loading = true;
      let companyCode = self.input.company_code;
      let userName = self.input.username.toLowerCase();
      if(userName != null && userName.length > 15){
          self.erroralert = true;
          self.errorMessage = "User name with more than 15 characters is not allowed.";
          self.loading = false;
          return;
      }
      let password = self.input.password;

      await self.$store
        .dispatch("login", { companyCode, userName, password })
        .then(async function (response) {
          self.successalert = true;
          self.successMessage = response.data.message;
          self.loading = false;
          self.model = { username: "", password: "" };
          const usertype = response.data.data.userType.trim();
          self.permissionID = response.data.data.permissionId;
          localStorage.setItem("companyID", response.data.data.companyID);
          localStorage.setItem("storeTemp", self.permissionID);
          localStorage.setItem("token", response.data.data.token);
          sessionStorage.setItem('pdpa', response.data.data.pdpa)
          if (usertype == "EXT") {
            setTimeout(async() => {
              // let MenuIds = sessionStorage.getItem("MenuId").split(",");
              // const first_MenuID = MenuIds[0];
              // let route = "usermanagement";
              // if (first_MenuID == 11) {
              //   route = "usermanagement";
              // } else if (first_MenuID == 22) {
              //   route = "usergroup";
              // } else if (first_MenuID == 33) {
              //   route = "permission";
              // } else if (first_MenuID == 1) {
              //   route = "menumanagement";
              // } else if (first_MenuID == 2) {
              //   route = "calendar";
              // } else if (first_MenuID == 3) {
              //   route = "log";
              // }
              // const min_per =  Math.min(...response.data.data.permissionId);
              // switch(min_per) {
              //   case 1:
              //   case 2:
              //     self.$router.push("/UploadPDF");
              //     break;
              //   case 3:
              //   case 4:
              //     self.$router.push("/historylog");
              //     break;
              //   case 5:
              //   case 6:
              //   case 7:
              //   case 8:
              //     self.$router.push("/CertList");
              //     break;
              //   case 9:
              //   case 10:
              //   case 11:
              //   case 12:
              //   case 13:
              //   case 14:
              //   case 15:
              //   case 16:
              //   case 17:
              //   case 18:
              //   case 19:
              //   case 20:
              //   case 21:
              //     self.$router.push("/usermanagement");
              //     break;
              //   default:
              //     self.$router.push("/AccessDenied");
              //     break;
              // }
            self.$router.push("/UploadPDF");
            }, 1000);
            // document.cookie =
            //   "username=" +
            //   self.cryptoMethods("encrypt", userName) +
            //   ";expires=" +
            //   self.expiryDate;
            // document.cookie =
            //   "password=" +
            //   self.cryptoMethods("encrypt", password) +
            //   ";expires=" +
            //   self.expiryDate;
            // let tempRem = self.rememberMe;
            // document.cookie = "rememberme=" + tempRem + ";expires=" + self.expiryDate;
          }
          self.flagLoadingSend = false;
          self.loading = false;
        })
        .catch(function (error) {
          self.erroralert = true;
          self.errorMessage = error;
          self.loading = false;
        });
    },
    clearMessage: function () {
      this.erroralert = false;
      this.successalert = false;
      this.errorMessage = "";
      this.successMessage = "";
    },
    readCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    cryptoMethods(type, value) {
      let self = this;
      if (value != null || value != "" || value != undefined) {
        if (type == "encrypt") {
          const encryptedText = this.CryptoJS.AES.encrypt(
            value,
            self.secretKey
          ).toString();
          return encryptedText;
        } else {
          const decryptedText = this.CryptoJS.AES.decrypt(
            value,
            self.secretKey
          ).toString(this.CryptoJS.enc.Utf8);
          return decryptedText;
        }
      }
    },
  },
};
</script>
<style scoped>
/* .forgotPassword {
  margin-top: 1.3rem;
}

.forgot {
  width: 10rem;
}

.login {
  width: 14rem;
}

.form {
  min-width: 50%;
}

.img-intersection {
  width: 100%;
  height: 100vh;
  max-width: 100%;
}

@media only screen and (max-width: 959px) {
  .logocss {
    display: none;
  }

  .rescss {
    margin: 1rem;
    width: 100% !important;
  }
} */
::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot 
{
  min-height : 40px !important;
}
.v-application .text-caption {
  font-size: 0.75rem !important;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.0333333333em !important;
  font-family: "Roboto", sans-serif !important;
}

.v-application .primary {
    background-color: #126496 !important;
    border-color: #126496 !important;
}

.rounded-0 {
  border-radius: 0px !important;
}

.rounded-lg {
  border-radius: 7px !important;
}

.btn-danger{
  background: #E82222 !important;
  color:#ffffff !important;
}
.btn-light{
  background: #FFFFFF !important;
  color:#444444 !important;
}

.btn-success{
  background: #218838!important;
  color:#ffffff !important;
}

.has_required_succeed{
    /* text-decoration: line-through; */
    color:#689868;
}
.has_required_unsuccessful{
    /* text-decoration: line-through; */
    color:#C62828;
}
</style>
