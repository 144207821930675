<template>
  <v-card
    style="
      box-shadow: 1px 1px 1px -1px rgb(0 0 0 / 3%),
        0px 1px 1px 1px rgb(0 0 0 / 3%), 0px 1px 1px 0px rgb(0 0 0 / 3%);
      padding-left: 16px;
      padding-right: 16px;
    "
  >
    <div style="margin-left: 10px; margin-right: 10px">
      <v-row no-gutters>
        <v-col>
          <h2 class="mt-4 mb-2 font-weight-bold">{{ $t("envelopeReport") }}</h2>
          <p class="text-body-2">{{ $t("envelopeReportDescription") }}</p>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4">
        <v-col>
          <p class="text-caption">
            <span class="font-weight-bold">{{ $t("filteredBy") }} : </span>
            <span> {{ filteredText }}</span>
          </p>
          <v-row class="mt-4 align-center">
            <v-col cols="12" md="2" style="margin-top: 26px">
              <label>{{ $t("dateRange") }}</label>
              <v-autocomplete
                autocomplete="off"
                v-model="dateRange"
                @keypress="$event.target.blur()"
                item-value="value"
                item-text="text"
                :items="dateRangeItems"
                @change="clickDateRange"
                solo
                dense
                :placeholder="$t('dateRange')"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2">
              <label>{{ $t("fromDate") }}</label>
              <v-menu
                v-model="menu1"
                transition="scale-transition"
                min-width="auto"
                offset-y
                :disabled="isDateRangeSelected"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-model="formatedDate1"
                    readonly
                    v-on="on"
                    outlined
                    :placeholder="$t('ddmmyyyy')"
                    :disabled="isDateRangeSelected"
                    dense
                    hide-details="auto"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="date1"
                  :allowed-dates="allowedDates_start"
                  no-title
                  @input="save1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2">
              <label>{{ $t("toDate") }}</label>
              <v-menu
                v-model="menu2"
                transition="scale-transition"
                min-width="auto"
                offset-y
                :disabled="isDateRangeSelected"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-model="formatedDate2"
                    readonly
                    v-on="on"
                    outlined
                    :placeholder="$t('ddmmyyyy')"
                    :disabled="isDateRangeSelected"
                    dense
                    hide-details="auto"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="date2"
                  :allowed-dates="allowedDates_end"
                  no-title
                  @input="save2"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3" style="margin-top: 26px">
              <label>{{ $t("envelopeDateType") }}</label>
              <v-autocomplete
                autocomplete="off"
                v-model="envelopeDateType"
                :items="envelopeDateTypeItems"
                @keypress="$event.target.blur()"
                solo
                dense
                :placeholder="$t('envelopeDateType')"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2">
              <br />
              <div class="text-center">
                <v-btn
                  class="text-center"
                  @click="ClickRunReport"
                  :style="[
                    $vuetify.breakpoint.smAndDown ? { width: '100%' } : '',
                  ]"
                  color="yellow darken-3"
                  style="text-transform: none"
                  >{{ $t("runReport") }}</v-btn
                >
              </div>
            </v-col>
            <v-col cols="12" md="2" lg="1">
              <br />
              <v-btn
              @click="ExportEnvelopeReport"
                :style="[
                  $vuetify.breakpoint.smAndDown ? { width: '100%' } : '',
                ]"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="text-caption">
            {{ $t("totalResults") }} : {{ dataCount }}
          </p>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="tableItems"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            :no-data-text="$t('nodataavailable')"
            hide-default-footer
            :sort-by-desc="['sentOn']"
            :header-props="{ 'sort-icon': 'mdi-menu-down' }"
          >
            <template v-slot:item.status="{ item }">
              <v-btn
                :color="getColor(item.status)"
                rounded
                depressed
                style="width: 150px"
              >
                <span :style="{ color: getColorText(item.status) }">
                  {{ statusItems(item.status) }}
                </span>
              </v-btn>
            </template>
            <template v-slot:item.recipientName="{ item }">
              <span
                v-if="
                  Array.isArray(item.recipientName) &&
                  item.recipientName.some((name) => name)
                "
              >
                <span v-for="(name, index) in item.recipientName" :key="index">
                  {{ name }}<br />
                </span>
              </span>
              <span v-else>-</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="5">
          <v-select
            dense
            style="width: 120px; float: left"
            solo
            label="10/page"
            v-model="itemsPerPage"
            @input="ChangePerPage(itemsPerPage)"
            :items="items"
          ></v-select>
        </v-col>
        <v-col>
          <v-pagination
            style="float: left"
            class="__pagination"
            v-model="page"
            :total-visible="6"
            :length="pageCount"
          >
          </v-pagination>
        </v-col>
      </v-row>
      <Alert
        :flagAlert="flagAlertSend"
        :typeAlert="typeAlertSend"
        :mesAlert="mesAlertSend"
        @flagClose="ClearDataAlert()"
      />
      <Loading :flagLoading="flagLoadingSend" />
    </div>
  </v-card>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import Alert from "@/components/Alert";
import Loading from "@/components/Loading";
import * as moment from "moment/moment";
import i18n from "@/plugins/i18n";

export default {
  components: {
    Alert,
    Loading,
  },
  data() {
    return {
      dateRange: null,
      date1: null,
      menu1: false,
      formatedDate1: null,
      date2: null,
      menu2: false,
      formatedDate2: null,
      envelopeDateType: 0,
      page: 1,
      pageCount: 0,
      dataCount: 0,
      items: [10, 20, 30],
      itemsPerPage: 10,
      flagLoadingSend: false,
      flagAlertSend: false,
      mesAlertSend: "",
      typeAlertSend: "success",
      url: enurl.apiUrl,
      tableItems: [],
    };
  },
  mounted() {
    const elements = document.querySelectorAll(".v-pagination__navigation");
    for (const [index, element] of elements.entries()) {
      if (index % 2 != 0) {
        element.textContent = this.$t("next");
      } else {
        element.textContent = this.$t("previous");
      }
    }
    this.dateRange = 6 || null;
    this.envelopeDateType =
      this.envelopeDateTypeItems.find((x) => x.value == 0) || null;
    const today = new Date();
    const startDate = new Date(today);
    const endDate = new Date(today);
    startDate.setFullYear(today.getFullYear(), today.getMonth(), 1);
    endDate.setFullYear(today.getFullYear(), startDate.getMonth() + 1, 0);
    this.date1 = startDate.toISOString().split("T")[0];
    this.date2 = endDate.toISOString().split("T")[0];
    this.formatedDate1 = this.formatDate(this.date1);
    this.formatedDate2 = this.formatDate(this.date2);
    this.GetEnvelopeReport();
  },
  computed: {
    locale(){
        return i18n.locale;
      },
      tableHeaders() {
        return [
          {
            text: this.$t("envelopeID"),
            value: "workflowID",
            sortable: false
          },
          {
            text: this.$t("documentName"),
            value: "documentName",
            sortable: false
          },
          {
            text: this.$t("subject"),
            value: "subject",
            sortable: false
          },
          {
            text: this.$t("status"),
            value: "status",
            sortable: false
          },
          {
            text: this.$t("senderName"),
            value: "senderName",
            sortable: false
          },
          {
            text: this.$t("recipientName"),
            value: "recipientName",
            sortable: false,
          },
          {
            text: this.$t("sentOn"),
            value: "sentOn",
            sortable: true },
          {
            text: this.$t("lastActivity"),
            value: "lastActivity",
            sortable: false,
          },
          {
            text: this.$t("completedOn"),
            value: "completedOn",
            sortable: false },
          {
            text: this.$t("completionTime1"),
            value: "completionTime",
            sortable: false,
          },
        ];
      },
      dateRangeItems() {
        return [
          { text: this.$t("yesterday"), value: 1 },
          { text: this.$t("thisWeek"), value: 2 },
          { text: this.$t("lastWeek"), value: 3 },
          { text: this.$t("last7Days"), value: 4 },
          { text: this.$t("last14Days"), value: 5 },
          { text: this.$t("thisMonth"), value: 6 },
          { text: this.$t("last30Days"), value: 7 },
          { text: this.$t("lastMonth"), value: 8 },
          { text: this.$t("last90Days"), value: 9 },
          { text: this.$t("last180Days"), value: 10 },
          { text: this.$t("thisYear"), value: 11 },
          { text: this.$t("last365Days"), value: 12 },
          { text: this.$t("lastYear"), value: 13 },
          { text: this.$t("custom"), value: 0 },
        ];
      },
      envelopeDateTypeItems() {
        return [
          { text: this.$t("sentDate"), value: 0 },
          { text: this.$t("completionDate"), value: 1 },
        ];
      },
      isDateRangeSelected() {
        return !!this.dateRange;
      },
      filteredText() {
        const dateRangeItem = typeof this.dateRange !== 'object' 
          ? this.dateRangeItems.find(x => x.value === this.dateRange)
          : this.dateRangeItems.find(x => x.value === this.dateRange?.value);
        const dateRangeText = dateRangeItem ? dateRangeItem.text : '';

      const envelopeDateTypeItem =
        typeof this.envelopeDateType !== "object"
          ? this.envelopeDateTypeItems.find(
              (x) => x.value === this.envelopeDateType
            )
          : this.envelopeDateTypeItems.find(
              (x) => x.value === this.envelopeDateType?.value
            );
      const envelopeDateTypeText = envelopeDateTypeItem
        ? envelopeDateTypeItem.text
        : "";
      return `${this.$t("date")} (${dateRangeText}), ${this.$t(
        "envelopeDateType"
      )} (${envelopeDateTypeText})`;
    },
  },
  watch: {
    page() {
      this.GetEnvelopeReportPerpage();
    },
    locale(){
      const elements = document.querySelectorAll(".v-pagination__navigation");

      for (const [index, element] of elements.entries()) {
        if (index % 2 != 0) {
          element.textContent = this.$t("next");
        } else {
          element.textContent = this.$t("previous");
        }
      }
     },
  },
  methods: {
    allowedDates_start(val) {
      var self = this;
      if (!self.formatedDate2 || self.dateRange != 0) {
        return true;
      } else {
        var moment_val = moment(val, "YYYY-MM-DD");
        var moment_end = moment(self.formatedDate2, "DD/MM/YYYY");
        var month_diff = moment_end.diff(moment_val, "month") < 3; //dates with 3 months difference
        var end_after_val = moment_end.isSameOrAfter(moment_val); //to disable dates before selected start date
        return month_diff && end_after_val;
      }
    },
    allowedDates_end(val) {
      var self = this;

      if (!self.formatedDate1 || self.dateRange != 0) {
        return true;
      } else {
        var moment_val = moment(val, "YYYY-MM-DD");
        var moment_start = moment(self.formatedDate1, "DD/MM/YYYY");
        var month_diff = moment_val.diff(moment_start, "months") < 3; //dates with 3 months difference
        var start_after_val = moment_val.isSameOrAfter(moment_start); //to disable dates after selected end date
        return month_diff && start_after_val;
      }
    },
    roundAndPad(number) {
      let rounded = Math.round(number);
      return String(rounded).padStart(2, "0"); // Ensures at least 2 digits
    },
    ChangePerPage(itemsPerPage) {
      this.page = 1;
      this.GetEnvelopeReportPerpage();
      return itemsPerPage;
    },
    save1(date1) {
      this.formatedDate1 = this.formatDate(date1);
      this.menu1 = false;
    },
    save2(date2) {
      this.formatedDate2 = this.formatDate(date2);
      this.menu2 = false;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    formattedDate(value) {
      return moment.utc(value).local().format("DD/MM/YYYY");
    },
    getTime(date) {
      console.log("date", date);
      if (date == null || date == "") return "-";
      var localDate = moment.utc(date).local();
      const hours = localDate.hours().toString().padStart(2, "0");
      const minutes = localDate.minutes().toString().padStart(2, "0");

      // Combine hours and minutes into HH:MM format
      return `${hours}:${minutes}`;
    },
    getColor(e) {
      if (e == 1) {
        return "#EAEAEA";
      } else if (e == 2) {
        return "rgb(250, 169, 27,0.3)";
      } else if (e == 3) {
        return "rgb(92, 184, 92,0.3)";
      } else if (e == 4) {
        return "rgb(18, 100, 150,0.3)";
      } else if (e == 5) {
        return "rgb(232, 34, 34,0.3)";
      } else {
        return "rgb(234, 234, 234)";
      }
    },
    getColorText(e) {
      if (e == 1) {
        return "#BFBFBF";
      } else if (e == 2) {
        return "rgb(250, 169, 27)";
      } else if (e == 3) {
        return "rgb(92, 184, 92)";
      } else if (e == 4) {
        return "rgb(18, 100, 150)";
      } else if (e == 5) {
        return "rgb(232, 34, 34)";
      } else {
        return "#126496";
      }
    },
    statusItems(e) {
      return e == 1
        ? this.$t("notStart")
        : e == 2
        ? this.$t("onProcess")
        : e == 3
        ? this.$t("complete")
        : e == 4
        ? this.$t("reject")
        : e == 5
        ? this.$t("cancel")
        : e == 6
        ? this.$t("draft")
        : this.$t("expired");
    },
    ClearDataAlert() {
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    async GetEnvelopeReport() {
      try {
        let self = this;
        self.flagLoadingSend = true;
        const apiUrl = `${self.url}Report/GetEnvelopeReport`;
        const temp = {
          page: 1,
          perPage: 10,
          sort: 0,
          dateType:
            typeof this.envelopeDateType != "object"
              ? this.envelopeDateType
              : this.envelopeDateType.value,
          startDate:
            this.date1 == "" || this.date1 == null
              ? this.formattedDate(new Date())
              : this.formattedDate(this.date1),
          endDate:
            this.date2 == "" || this.date2 == null
              ? this.formattedDate(new Date())
              : this.formattedDate(this.date2),
        };
        const response = await axios.post(apiUrl, temp);
        if (response.data.status === 0) {
          this.tableItems = response.data.data.listData.map((v) => ({
            ...v,
            workflowID:
              v.workflowID == null || v.workflowID == "" ? "-" : v.workflowID,
            subject: v.subject == null || v.subject == "" ? "-" : v.subject,
            status: v.status == null || v.status == "" ? "-" : v.status,
            senderName:
              v.senderName == null || v.senderName == "" ? "-" : v.senderName,
            recipientName:
              v.recipientList.length > 0
                ? v.recipientList.map((recipient) => recipient.recipientName)
                : [],
            sentOn:
              v.sentOn == null || v.sentOn == ""
                ? "-"
                : this.formattedDate(v.sentOn),
            lastActivity:
              v.lastActivity == null || v.lastActivity == ""
                ? "-"
                : this.formattedDate(v.lastActivity),
            completedOn:
              v.completedOn == null || v.completedOn == ""
                ? "-"
                : this.formattedDate(v.completedOn),
            completionTime:
              v.completedOn == null || v.completedOn == ""
                ? "-"
                : self.getTime(v.completedOn),
            // `${self.roundAndPad(v.completionTime.split(":")[0])}:${self.roundAndPad(v.completionTime.split(":")[1])}:${self.roundAndPad(v.completionTime.split(":")[2])}` ,
          }));
          self.pageCount = response.data.data.count;
          self.itemsPerPage = 10;
          self.page = 1;
          self.dataCount = response.data.data.dataCount;
          self.flagLoadingSend = false;
        } else {
          self.tableItems = [];
          self.pageCount = 0;
          self.dataCount = 0;
          self.itemsPerPage = 10;
          self.page = 1;
          self.flagLoadingSend = false;
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async GetEnvelopeReportPerpage() {
      try {
        let self = this;
        self.flagLoadingSend = true;
        const apiUrl = `${self.url}Report/GetEnvelopeReport`;
        const temp = {
          page: this.page,
          perPage: this.itemsPerPage,
          sort: 0,
          dateType:
            typeof this.envelopeDateType != "object"
              ? this.envelopeDateType
              : this.envelopeDateType.value,
          startDate:
            this.date1 == "" || this.date1 == null
              ? this.formattedDate(new Date())
              : this.formattedDate(this.date1),
          endDate:
            this.date2 == "" || this.date2 == null
              ? this.formattedDate(new Date())
              : this.formattedDate(this.date2),
        };
        const response = await axios.post(apiUrl, temp);
        if (response.data.status === 0) {
          this.tableItems = response.data.data.listData.map((v) => ({
            ...v,
            workflowID:
              v.workflowID == null || v.workflowID == "" ? "-" : v.workflowID,
            subject: v.subject == null || v.subject == "" ? "-" : v.subject,
            status: v.status == null || v.status == "" ? "-" : v.status,
            senderName:
              v.senderName == null || v.senderName == "" ? "-" : v.senderName,
            recipientName:
              v.recipientList.length > 0
                ? v.recipientList.map((recipient) => recipient.recipientName)
                : [],
            sentOn:
              v.sentOn == null || v.sentOn == ""
                ? "-"
                : this.formattedDate(v.sentOn),
            lastActivity:
              v.lastActivity == null || v.lastActivity == ""
                ? "-"
                : this.formattedDate(v.lastActivity),
            completedOn:
              v.completedOn == null || v.completedOn == ""
                ? "-"
                : this.formattedDate(v.completedOn),
            completionTime:
              v.completedOn == null || v.completedOn == ""
                ? "-"
                : self.getTime(v.completedOn),
            // `${self.roundAndPad(v.completionTime.split(":")[0])}:${self.roundAndPad(v.completionTime.split(":")[1])}:${self.roundAndPad(v.completionTime.split(":")[2])}` ,
          }));
          self.pageCount = response.data.data.count;
          self.dataCount = response.data.data.dataCount;
          self.flagLoadingSend = false;
        } else {
          self.tableItems = [];
          self.pageCount = 0;
          self.dataCount = 0;
          self.page = 1;
          self.flagLoadingSend = false;
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    ExportEnvelopeReport() {
      let self = this;
      self.flagLoadingSend = true;
      let tempdata = {
        Page: 1,
        PerPage: self.itemsPerPage * self.pageCount,
        languageUsed: sessionStorage.getItem("flag"),
        timeZoneOFfset: new Date().getTimezoneOffset() * -1,
        dateType:
            typeof this.envelopeDateType != "object"
              ? this.envelopeDateType
              : this.envelopeDateType.value,
          startDate:
            this.date1 == "" || this.date1 == null
              ? this.formattedDate(new Date())
              : this.formattedDate(this.date1),
          endDate:
            this.date2 == "" || this.date2 == null
              ? this.formattedDate(new Date())
              : this.formattedDate(this.date2),
      };
      axios
        .post(`${self.url}Report/ExportEnvelopeReport`, tempdata, {
          responseType: "blob",
        })
        .then(function (response) {
          if (response.status === 200) {
            const contentDisposition = response.headers['content-disposition'];
            let filename = 'EnvelopeReport.xlsx'; // Fallback filename

            if (contentDisposition) {
            const match = contentDisposition.match(/filename\*?=(?:UTF-8'')?([^;]+)/);
            if (match && match[1]) {
                filename = decodeURIComponent(match[1].replace(/['"]/g, ''));
            }
            }
            
            const blob = new Blob([response.data], {
              type: "application/vnd.ms-excel",
            });
          
            const linkSource = URL.createObjectURL(blob);
            var link = document.createElement("a");
            link.download = filename;
            link.href = linkSource;
            link.click();

            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
          }
        })
        .catch((error) => {
          console.log(error);
          self.flagLoadingSend = false;
        });
    },
    async ClickRunReport() {
      await this.GetEnvelopeReport();
      // this.checkPerPage();
    },
    //when we select perpage to 20 or 30
    checkPerPage() {
      if (this.itemsPerPage == 20 || this.itemsPerPage == 30) {
        // when data count is greater than 20, we take perpage value to 30
        if (this.dataCount > 20) {
          this.itemsPerPage = 30;
        }
        // when data count is greater than 10 & less than or equal 20, we take perpage value to 20
        else if (this.dataCount > 10 && this.dataCount <= 20) {
          this.itemsPerPage = 20;
        }
        // when data count is less than or equal 10, we take perpage value to 10
        else if (this.dataCount <= 10) {
          this.itemsPerPage = 10;
        }
      }
    },
    clickDateRange() {
      const today = new Date();
      const startDate = new Date(today);
      const endDate = new Date(today);
      if (this.dateRange === 1) {
        startDate.setDate(today.getDate() - 1);
        this.date1 = startDate.toISOString().split("T")[0];
        this.date2 = startDate.toISOString().split("T")[0];
      } else if (this.dateRange === 2) {
        startDate.setDate(today.getDate() - today.getDay());
        endDate.setDate(startDate.getDate() + 6);
        this.date1 = startDate.toISOString().split("T")[0];
        this.date2 = endDate.toISOString().split("T")[0];
      } else if (this.dateRange === 3) {
        startDate.setDate(today.getDate() - today.getDay() - 7);
        endDate.setDate(today.getDate() - today.getDay() - 1);
        this.date1 = startDate.toISOString().split("T")[0];
        this.date2 = endDate.toISOString().split("T")[0];
      } else if (this.dateRange === 4) {
        startDate.setDate(today.getDate() - 6);
        this.date1 = startDate.toISOString().split("T")[0];
        this.date2 = today.toISOString().split("T")[0];
      } else if (this.dateRange === 5) {
        startDate.setDate(today.getDate() - 13);
        this.date1 = startDate.toISOString().split("T")[0];
        this.date2 = today.toISOString().split("T")[0];
      } else if (this.dateRange === 6) {
        startDate.setFullYear(today.getFullYear(), today.getMonth(), 1);
        endDate.setFullYear(today.getFullYear(), startDate.getMonth() + 1, 0);
        this.date1 = startDate.toISOString().split("T")[0];
        this.date2 = endDate.toISOString().split("T")[0];
      } else if (this.dateRange === 7) {
        startDate.setDate(today.getDate() - 29);
        this.date1 = startDate.toISOString().split("T")[0];
        this.date2 = today.toISOString().split("T")[0];
      } else if (this.dateRange === 8) {
        startDate.setFullYear(today.getFullYear(), today.getMonth() - 1, 1);
        endDate.setFullYear(today.getFullYear(), today.getMonth(), 0);
        this.date1 = startDate.toISOString().split("T")[0];
        this.date2 = endDate.toISOString().split("T")[0];
      } else if (this.dateRange === 9) {
        startDate.setDate(today.getDate() - 89);
        this.date1 = startDate.toISOString().split("T")[0];
        this.date2 = today.toISOString().split("T")[0];
      } else if (this.dateRange === 10) {
        startDate.setDate(today.getDate() - 179);
        this.date1 = startDate.toISOString().split("T")[0];
        this.date2 = today.toISOString().split("T")[0];
      } else if (this.dateRange === 11) {
        startDate.setFullYear(today.getFullYear(), 0, 1);
        endDate.setFullYear(today.getFullYear(), 11, 31);
        this.date1 = startDate.toISOString().split("T")[0];
        this.date2 = endDate.toISOString().split("T")[0];
      } else if (this.dateRange === 12) {
        startDate.setDate(today.getDate() - 364);
        this.date1 = startDate.toISOString().split("T")[0];
        this.date2 = today.toISOString().split("T")[0];
      } else if (this.dateRange === 13) {
        startDate.setFullYear(today.getFullYear() - 1, 0, 1);
        endDate.setFullYear(today.getFullYear() - 1, 12, 0);
        this.date1 = startDate.toISOString().split("T")[0];
        this.date2 = endDate.toISOString().split("T")[0];
      } else {
        this.date1 = "";
        this.date2 = "";
      }
      this.formatedDate1 = this.formatDate(this.date1);
      this.formatedDate2 = this.formatDate(this.date2);
    },
  },
};
</script>
  
<style scoped>
.theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-pagination .v-pagination__item--active {
  background: #126496 !important;
  color: white !important;
}
.text-right {
  text-align: right;
}
.text-body-2 {
  font-size: 0.875rem;
  font-weight: bold;
}
</style>
  